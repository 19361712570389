import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";

const ToastNotification = ({
  show,
  onClose,
  message,
  position = "top-center",
  delay = 3000,
  autohide = true,
  variant = "success"
}) => {
  const getVariantClass = () => {
    switch (variant) {
      case "success":
        return "bg-success text-white"; // Bootstrap success class
      case "error":
        return "bg-danger text-white"; // Bootstrap error class
      default:
        return "bg-primary text-white"; // Fallback
    }
  };

  return (
    <ToastContainer position={position} className="p-3">
      <Toast onClose={onClose} show={show} delay={delay} autohide={autohide}  className={getVariantClass()}>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ToastNotification;
