import React, { useEffect, useState } from "react";
import {Link} from 'react-router-dom'
import Rzllogo from "../../assets/Icons/Logo";
const Logo = () => {
const [admin,setAdmin]=useState(true)
const data=JSON.parse(localStorage.getItem("userData"))
const [data2,setData]=useState(data?data:'')
console.log(data2,'data2');

  useEffect(()=>{
if (data2.isAdmin==true) {
  setAdmin(true)  
}else{
  setAdmin(false)
}
  },[data2])
  return (

    
      admin? <div className="header-log">
    <Rzllogo/>
    </div>: <Link to='/'>
    <div className="header-log">
    <Rzllogo/>
    </div>
    </Link>
  
    
  );
};

export default Logo;
