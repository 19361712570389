import React, { useEffect } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import "./LayoutComponent.scss";
import { useNavigate } from "react-router-dom";

const LayoutComponent = ({ children, layoutType, element }) => {
  const navigate = useNavigate();

  const authToken = localStorage.getItem("authToken");

  let user = null;
  try {
    user = authToken ? JSON.parse(localStorage.getItem("userData")) : null;
  } catch (error) {
    console.error("Invalid user data in localStorage:", error);
  }


  useEffect(() => {
    if (element.wrapRoute === "protectedRoute" && user && user.isAdmin == true) {
      navigate("/admin-dashboard");
    }
    if(element.wrapRoute !== "publicRoute" &&  element.wrapRoute !== "authRoute" && !authToken){
      navigate("/login");
    }

  }, [user, navigate,element]);

  return (
    <div className="main-site-wrapper">
      <Header />
      <div className={`content-wrapper`}>
        <div className="content-area">
          {element.wrapRoute === "protectedRoute" && user?.isAdmin == false
            ? children
            : element.wrapRoute !== "protectedRoute"
            ? children
            : null
          }
        </div>
      </div>
      {layoutType !== "admin" && <Footer />}
    </div>
  );
};

export default LayoutComponent;
