import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import RoutesComponent from "./Routes";
import { LoadingProvider } from "./context/LoadingContext";

function App() {
  return (
    <>
      <LoadingProvider>
        <RoutesComponent />
      </LoadingProvider>
    </>
  );
}

export default App;
