import React, { createContext, useState, useContext } from "react";
import ToastNotification from "../components/Toast/Toast";

const LoadingContext = createContext();

export const useLoading = () => {
  return useContext(LoadingContext);
};

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formId,setFormId]=useState("")
  const [toast, setToast] = useState({
    show: false,
    message: "",
    position: "top-center",
    delay: 3000,
    autohide: true,
    variant: "success",
  });

  const closeToast = () => {
    setToast((prev) => ({ ...prev, show: false }));
  };

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading ,toast, setToast,formId,setFormId}}>
      {children}
      <ToastNotification
        show={toast.show}
        onClose={closeToast}
        message={toast.message}
        position={toast.position}
        delay={toast.delay}
        autohide={toast.autohide}
        variant={toast.variant}
      />
    </LoadingContext.Provider>
  );
};
