import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element, ...props }) => {
  const isAuthenticated = localStorage.getItem("authToken") 

  return isAuthenticated ? (
    element
  ) : (
    <Navigate
      to="/"
      replace
      state={ {
        from: props.location,
      } }
    />
  );
};

export default ProtectedRoute;
