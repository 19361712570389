import * as React from "react"
const BarIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={ 24 }
        height={ 24 }
        fill="none"
        { ...props }
    >
        <path
            stroke="#292D32"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={ 1.5 }
            d="M3 4.5h18M3 9.5h18M3 14.5h18M3 19.5h18"
        />
    </svg>
)
export default BarIcon
