import React from "react";
import { Navigate } from "react-router-dom";


const AuthRoute = ({ element }) => {

  const isAuthenticated = localStorage.getItem("authToken") 

  return isAuthenticated ? <Navigate to={`/dashboard`} replace /> : element;
};

export default AuthRoute;
